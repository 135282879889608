import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Image, Box, RoundedLabel, Icons, ItemInfoModal, ItemStatusLabel, List, SmallTagCounter } from 'components'
import { CustomTmrItem, TmrItem } from 'api/types'
import Products from 'api/Products'
import { T, __ } from 'translations/i18n'
import Shipments from 'api/Shipments'
import { getChoiceDescription, getConcatCodeDescription } from 'shared/customUtils'
import { Spacer } from './Various'

interface Props {
  id: any
  item: CustomTmrItem
  onInfoPress?: (item: CustomTmrItem) => void
  onDeleteCallback?: (item: TmrItem) => void
  itemState?: 'ERROR' | 'WARNING' | 'IGNORE'
  hideRowParcelCode?: boolean
  counter?: number
}

interface State {
  visibleItemModal: boolean
  parcelCode?: string
}

export default class CustomItemRow extends Component<Props, State> {
  state: State = {
    visibleItemModal: false,
  }

  async componentDidMount() {
    const { item, hideRowParcelCode } = this.props
    try {
      if (!item.itemIdentifiers || item.itemIdentifiers.length === 0 || !item.itemIdentifiers[0] || hideRowParcelCode)
        return

      const parcel = await Shipments.getParcelCodeByIdentifier(item.itemIdentifiers[0].code)
      this.setState({ parcelCode: parcel.value })
    } catch (error) {
      console.log(error)
    }
  }

  openItemInfoModal = () => this.setState({ visibleItemModal: true })
  closeItemInfoModal = () => this.setState({ visibleItemModal: false })

  onRowPress = () => {
    const { onInfoPress, item } = this.props
    if (onInfoPress) return () => onInfoPress(item)
    if (!item.id) return undefined
    if (!onInfoPress) return this.openItemInfoModal
    return undefined
  }

  getItemMessages = () => {
    const { item, itemState } = this.props
    const messages: { message: string; status: 'error' | 'warning' }[] = []
    item.__processedStates
      ?.filter((state) => (itemState ? state.processedState === itemState : true))
      ?.forEach((processedState) => {
        if (processedState.itemState === 'ITEM_NOT_IN_INBOUND')
          messages.push({
            message: __(T.error.item_not_in_inbound),
            status: processedState.processedState.toLowerCase() as 'error' | 'warning',
          })
        if (processedState.itemState === 'ITEM_NOT_IN_STOCK')
          messages.push({
            message: __(T.error.item_not_in_stock),
            status: processedState.processedState.toLowerCase() as 'error' | 'warning',
          })
        if (processedState.itemState === 'ITEM_IN_ANOTHER_PARCEL')
          messages.push({
            message: __(T.error.item_in_another_parcel),
            status: processedState.processedState.toLowerCase() as 'error' | 'warning',
          })
        if (processedState.itemState === 'ITEM_IN_ANOTHER_PRODUCTION_ORDER')
          messages.push({
            message: __(T.error.item_in_another_production_order),
            status: processedState.processedState.toLowerCase() as 'error' | 'warning',
          })
        if (processedState.itemState === 'ITEM_IN_CHANGE_CODE')
          messages.push({
            message: __(T.error.item_in_change_code),
            status: processedState.processedState.toLowerCase() as 'error' | 'warning',
          })
        if (processedState.itemState === 'ITEM_IN_EXECUTION_PHASE')
          messages.push({
            message: __(T.error.item_in_execution_phase),
            status: processedState.processedState.toLowerCase() as 'error' | 'warning',
          })
        if (processedState.itemState === 'ITEM_WITH_ERROR_CHOICE')
          messages.push({
            message: __(T.error.item_with_error_choice),
            status: processedState.processedState.toLowerCase() as 'error' | 'warning',
          })
        if (processedState.itemState === 'ITEM_WITH_ERROR_FLAW')
          messages.push({
            message: __(T.error.item_with_error_flaw),
            status: processedState.processedState.toLowerCase() as 'error' | 'warning',
          })
        if (processedState.itemState === 'ITEM_WITH_NOT_ENOUGH_PRINTING')
          messages.push({
            message: __(T.error.item_with_enough_printing),
            status: processedState.processedState.toLowerCase() as 'error' | 'warning',
          })
        if (processedState.itemState === 'ITEM_WITH_ERROR_CQ')
          messages.push({
            message: __(T.error.item_with_error_cq),
            status: processedState.processedState.toLowerCase() as 'error' | 'warning',
          })
        if (processedState.itemState === 'ITEM_WITH_ERROR_RPAC')
          messages.push({
            message: __(T.error.item_with_error_rpac),
            status: processedState.processedState.toLowerCase() as 'error' | 'warning',
          })
        if (processedState.itemState === 'ITEM_WITH_ERROR_MADE_FOR')
          messages.push({
            message: __(T.error.item_with_error_madefor),
            status: processedState.processedState.toLowerCase() as 'error' | 'warning',
          })
        if (processedState.itemState === 'ITEM_ALREADY_IN_REPAIR')
          messages.push({
            message: __(T.error.item_already_in_repair),
            status: processedState.processedState.toLowerCase() as 'error' | 'warning',
          })
      })

    if (messages.length === 0) return undefined

    return messages
  }

  onDeletePress = () => {
    const { onDeleteCallback, item } = this.props
    if (onDeleteCallback) return () => onDeleteCallback(item)
    return undefined
  }

  render() {
    const { item, id, onDeleteCallback, counter } = this.props
    const { visibleItemModal, parcelCode } = this.state
    if (!item) return <></>
    const { product } = item

    let tagType
    if (!item.product) tagType = __(T.misc.unknown_product)
    if (!item.product && item.upc) tagType = __(T.misc.unknown_tag)
    if (!item.upc && !item.product) tagType = __(T.misc.invalid_tag)

    const upc = item.upc ?? item.product?.code
    const certilogo =
      item?.itemIdentifiers?.find((itemId) => itemId.identifierType === 'CertilogoIdentifier')?.code ?? '---'

    const messages = this.getItemMessages()
    const flawText = getConcatCodeDescription(item.flaw)
    const phaseText = getConcatCodeDescription(item.phase)
    const tom = item.productionOrderRow?.order?.code

    let isItemChange = item.changeStatus && item.changeStatus !== 'NONE'

    return (
      <Row row id={id} onClick={onDeleteCallback ? undefined : this.onRowPress()}>
        <Box center p={10}>
          <ProductImage src={Products.getImageUrl(product?.code)} alt="item" />
        </Box>

        <Separator />

        <Box flex p={20} pr={0}>
          <Box style={{ maxWidth: 'fit-content', wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
            <ProductCode>{upc ?? __(T.misc.unknown).toUpperCase()}</ProductCode>
            <ItemEpc>{item.product?.description ?? item.epc}</ItemEpc>

            <ItemEpc>CERTILOGO: {certilogo}</ItemEpc>
            {!!parcelCode && (
              <ParcelCode>
                {__(T.misc.parcel)}: {parcelCode}
              </ParcelCode>
            )}
            {!!tom && (
              <ParcelCode>
                {__(T.misc.production_order)}: {tom}
              </ParcelCode>
            )}
          </Box>
          <Spacer />
          <Box row style={{ justifyContent: 'flex-start', marginBottom: 10 }}>
            <RoundedLabel maxWidth={230} label={__(T.misc.style)} value={product?.style?.valueDescription} />
            <RoundedLabel maxWidth={130} label={__(T.misc.size)} value={product?.size?.value} />
            <RoundedLabel maxWidth={150} label={__(T.misc.color)} value={product?.color?.value} />
            <RoundedLabel value={tagType?.toUpperCase()} />
          </Box>
          <Box row style={{ justifyContent: 'flex-start', marginBottom: 10 }}>
            <RoundedLabel maxWidth={150} label={__(T.misc.choiche)} value={getChoiceDescription(item.choice)} />
            <RoundedLabel label={__(T.fields.flaw_reason)} value={flawText} />
            <RoundedLabel maxWidth={150} label={__(T.misc.madein)} value={item.madeIn?.code} />
            <RoundedLabel
              label={__(T.fields.quality_control)}
              value={item.qualityControl === 'NONE' ? undefined : item.qualityControl}
            />
          </Box>
          <Box row style={{ justifyContent: 'flex-start' }}>
            <RoundedLabel
              maxWidth={220}
              label={__(T.fields.made_for)}
              value={item.madeFor?.description ?? item.madeFor?.code ?? undefined}
            />
            <RoundedLabel
              maxWidth={220}
              label={__(T.fields.tone)}
              value={item.tone ? item.tone.toString() : undefined}
            />
            <RoundedLabel maxWidth={220} label={__(T.misc.tubful)} value={item.tubful ?? undefined} />
            <RoundedLabel maxWidth={220} label={__(T.misc.season)} value={item.season ?? undefined} />
            <RoundedLabel label={__(T.misc.phase)} value={phaseText} />
          </Box>
          {isItemChange && (
            <Box row style={{ justifyContent: 'flex-start' }}>
              <RoundedLabel maxWidth={500} label={__(T.fields.change_status)} value={item.changeStatus} />
            </Box>
          )}
          {!!messages && (
            <Box row style={{ justifyContent: 'flex-start', marginTop: 10 }} flex>
              {messages &&
                messages.map((message) => <ItemStatusLabel status={message.status} value={message.message} />)}
            </Box>
          )}
        </Box>
        {onDeleteCallback && (
          <Box p={30} center onClick={this.onDeletePress()}>
            <Icons.Delete />
          </Box>
        )}
        {this.onRowPress() && !onDeleteCallback && (
          <Box p={30} center>
            <Icons.Info />
          </Box>
        )}
        {counter && (
          <Box p={40} center>
            <SmallTagCounter detected={counter} />
          </Box>
        )}
        {visibleItemModal && <ItemInfoModal visible={visibleItemModal} item={item} onClose={this.closeItemInfoModal} />}
      </Row>
    )
  }
}

const Row = styled(Box)`
  margin-bottom: 20px;
  border: 2px solid #edecec;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
`

const Separator = styled.div`
  width: 2px;
  height: 100%;
  background-color: #edecec;
`

const ProductImage = styled(Image)`
  width: 100px;
  height: 100px;
  padding: 10px;
`

const ProductCode = styled.div`
  font-weight: 900;
  font-size: 28px;
`

const ItemEpc = styled.div`
  font-weight: 400;
  font-size: 20px;
`

const ParcelCode = styled.div`
  font-weight: 400;
  font-size: 18px;
`
