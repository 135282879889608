import { ApisauceConfig } from 'apisauce'
import api, { responseErrorCheck } from './api'
import {
  ChangeCodeManagement,
  CustomTmrItem,
  PrintRequest,
  ProductionOrderCounter,
  QualityControl,
  QualityControlResponse,
  SeasonId,
} from './types'
import { CustomUpdateRequest } from './Items'
import TmrBaseResource from './TmrBaseResource'

interface RepairRequest {
  itemId: string
  reason: string
}

export default class CustomItems extends TmrBaseResource {
  static endpoint = '/custom/items'

  static requestRepair(request: RepairRequest, requestConfig?: ApisauceConfig) {
    return api
      .post<any>(`${this.endpoint}/repair`, request, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res.data)
  }

  static requestChangeCode(itemIds: string[], requestConfig?: ApisauceConfig) {
    return api
      .post<any>(`${this.endpoint}/change`, itemIds, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res.data)
  }

  static updateChangeCode(itemIds: string[], requestConfig?: ApisauceConfig) {
    return api
      .post<any>(`${this.endpoint}/change/update`, itemIds, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res.data)
  }

  static customUpdate(request: CustomUpdateRequest, requestConfig?: ApisauceConfig) {
    return api
      .post<any>(`${this.endpoint}/update`, request, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res.data)
  }

  static changheChoiceUpdate(request: CustomUpdateRequest, userId: string, requestConfig?: ApisauceConfig) {
    return api
      .post<any>(`${this.endpoint}/updateChoice?userId=${userId}`, request, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res.data)
  }

  static fetchPendingChangeCodeRequests(itemId: string, requestConfig?: ApisauceConfig) {
    return api
      .post<any>(`${this.endpoint}/change/pending`, undefined, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res.data)
  }

  static getQualityControlInfo(request: CustomUpdateRequest, type: QualityControl, requestConfig?: ApisauceConfig) {
    return api
      .post<QualityControlResponse>(`${this.endpoint}/qualityControl/info?type=${type}`, request, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static getQualityControlStireriaInfo(
    request: CustomUpdateRequest,
    type: QualityControl,
    requestConfig?: ApisauceConfig
  ) {
    return api
      .post<QualityControlResponse>(`${this.endpoint}/qualityControlStireria/info?type=${type}`, request, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static updateQualityControl(request: CustomUpdateRequest, type: QualityControl, requestConfig?: ApisauceConfig) {
    return api
      .post<CustomTmrItem>(`${this.endpoint}/qualityControl/update?type=${type}`, request, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static updateQualityControlStireria(
    request: CustomUpdateRequest,
    type: QualityControl,
    requestConfig?: ApisauceConfig
  ) {
    return api
      .post<CustomTmrItem>(`${this.endpoint}/qualityControlStireria/update?type=${type}`, request, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static printEnvelopeLabel(
    itemId: string,
    madeFor?: string,
    printerCode?: string,
    printsNumber?: string
  ): Promise<PrintRequest> {
    let params = `${
      !!madeFor
        ? `?madeFor=${madeFor}${!!printerCode ? `&printer.code=${printerCode}` : ''}${
            !!printsNumber ? `&prints=${printsNumber}` : ''
          }`
        : `${!!printerCode ? `?printer.code=${printerCode}` : ''}${!!printsNumber ? `&prints=${printsNumber}` : ''}`
    }`
    return api
      .get<PrintRequest>(`${this.endpoint}/print/${itemId}/envelopeLabel${params}`)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static checkSeason(identifier?: string, itemId?: string, parcelCode?: string): Promise<string> {
    let param = !!identifier
      ? `identifier=${identifier}`
      : !!itemId
      ? `itemId=${itemId}`
      : !!parcelCode
      ? `parcelCode=${parcelCode}`
      : ``

    return api
      .get<string>(`${this.endpoint}/checkSeason?${param}`)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  //Manage
  static getChangeCodeToManage(orderCode: string) {
    return api
      .get<CustomTmrItem[]>(`${this.endpoint}/change/management/list?orderCode=${orderCode}`)
      .then(responseErrorCheck)
      .then((res) => res)
  }
  static manageChangeCode(dto: ChangeCodeManagement) {
    return api
      .post<CustomTmrItem[]>(`${this.endpoint}/change/management/confirm`, dto)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static getRequestedChangeByCurrentUser() {
    return api.get<CustomTmrItem[]>(`${this.endpoint}/change/management/listByUser`).then(responseErrorCheck)
  }

  static rejectChangeCode(dto: ChangeCodeManagement) {
    return api
      .post<CustomTmrItem[]>(`${this.endpoint}/change/approval/reject`, dto)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  //Approve
  static getChangeCodeToApproveCounter(placeCode: string) {
    return api
      .get<ProductionOrderCounter[]>(`${this.endpoint}/change/approval/counters?placeCode=${placeCode}`)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static getChangeCodeToApprove(orderCode: string) {
    return api
      .get<CustomTmrItem[]>(`${this.endpoint}/change/approval/list?orderCode=${orderCode}`)
      .then(responseErrorCheck)
      .then((res) => res)
  }
  static approveChangeCode(dtos: ChangeCodeManagement[]) {
    return api
      .post<CustomTmrItem[]>(`${this.endpoint}/change/approval/confirm`, dtos)
      .then(responseErrorCheck)
      .then((res) => res)
  }
}
