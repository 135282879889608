import React, { Component } from 'react'
import { Box, Button, Icons, Input, List, Page, Spacer } from 'components'
import { showToast } from 'shared/utils'
import { __, T } from 'translations/i18n'
import { navigate } from 'shared/router'
import CustomItems from 'api/CustomItems'
import { ProductionOrderCounter } from 'api/types'
import AppStore from '../../AppStore'
import CustomShipmentParcelRow from '../../components/ProductionOrderCounterRow'

interface State {
  query: string
  loading: boolean
  orderCounterRows: ProductionOrderCounter[]
}

export default class ChangeCodeApproval extends Component<{}> {
  state: State = {
    query: '',
    loading: false,
    orderCounterRows: [],
  }

  componentDidMount() {
    this.setState({ loading: true })
    CustomItems.getChangeCodeToApproveCounter(AppStore.loggedUser?.place.code ?? '')
      .then((data) => {
        this.setState({ loading: false, orderCounterRows: data ?? [] })
      })
      .catch((err) => {
        this.setState({ loading: false, orderCounterRows: [] })
      })
  }

  onRowClick = async (itm: ProductionOrderCounter | string) => {
    const orderCode = typeof itm === 'string' ? itm : itm.productionOrder.code
    //string is null or empty -> do not call BE
    if (!orderCode) return

    try {
      const items = await CustomItems.getChangeCodeToApprove(orderCode)

      if (items?.length === 0) {
        showToast({
          title: __(T.error.error),
          description: __(T.error.no_item_in_change_await_state),
          status: 'error',
        })
        return
      }

      navigate('/change-code/approval/association/:orderCode', { orderCode: orderCode })
    } catch (e) {
      showToast({ title: __(T.error.error), description: e?.message ?? e, status: 'error' })
    }
  }

  onInputEnter = (input: string) => {
    const { orderCounterRows } = this.state
    this.setState({ query: input })
    const filtered = orderCounterRows.filter(this.filterResults)

    if (filtered.length === 1) {
      this.onRowClick(filtered[0])
    }
  }

  navigateBack = () => {
    this.resetState()
    navigate('/')
  }

  resetState = () => {
    this.setState({
      query: '',
      loading: false,
      orderCounterRows: [],
    })
  }

  filterResults = (result: ProductionOrderCounter) =>
    result.productionOrder.code.toLowerCase().includes(this.state.query.toLowerCase())

  render() {
    const { orderCounterRows, loading } = this.state
    const filtered = orderCounterRows.filter(this.filterResults)

    return (
      <Page title={__(T.titles.approve_change_code)} onBackPress={() => this.navigateBack()}>
        <Page.Content bgGrey>
          <Page.TopBar>
            <Input
              startFocus
              placeholder={__(T.placeholder.search_production_order)}
              onChange={(query) => this.setState({ query })}
              image={<Icons.Barcode />}
              style={{ width: 350 }}
              onEnter={this.onInputEnter}
            />
            <Spacer />

            <Box flex />
            <Button title={__(T.misc.search)} onClick={() => this.onRowClick(this.state.query)} />
          </Page.TopBar>

          <List
            loading={loading}
            title={`${__(T.titles.last_orders)} (${filtered.length})`}
            renderItem={(itm) => <CustomShipmentParcelRow orderCounterRow={itm} onRowClick={this.onRowClick} />}
            data={filtered}
          />
        </Page.Content>
      </Page>
    )
  }
}
