import React, { Component } from 'react'
import { CustomTmrItem } from 'api/types'
import { Box, List } from 'components'
import styled from '@emotion/styled'
import { __, T } from 'translations/i18n'

interface Props {
  items: CustomTmrItem[]
  checkboxHandleChange?: (event) => void
  checkboxHandleChangeGroup?: (event) => void
  checkedIds: string[]
  disabled?: boolean
  checkboxLabel?: string
  checkboxLabelGroup?: string
}

interface State {
  expanded: boolean
}

export default class ChangeCodeVariantRow extends Component<Props> {
  state: State = {
    expanded: false,
  }

  getMadeFor = (item: CustomTmrItem) => {
    const madeFor = item?.madeFor
    return madeFor || '---'
  }

  getTone = (item: CustomTmrItem) => {
    const tone = item?.tone
    return tone || '---'
  }

  ellipsisLabel = (label?: string) => {
    if (!label || label.length < 1) return '---'
    if (label.length <= 10) return label
    return `${label.slice(0, 10)}...`
  }

  toggleExpanded = () => {
    const { expanded } = this.state

    this.setState({ expanded: !expanded })
  }

  render() {
    const {
      items,
      checkboxHandleChange,
      checkboxHandleChangeGroup,
      checkedIds,
      checkboxLabel,
      checkboxLabelGroup,
      disabled,
    } = this.props
    const { expanded } = this.state
    const firstItem = items[0]
    return (
      <>
        <RowItemGrouped onClick={this.toggleExpanded}>
          <List.Cell style={{ width: 200 }} label={__(T.misc.upc)} value={firstItem.upc} />
          <List.Cell style={{ width: 200 }} label={__(T.misc.status)} value={firstItem.changeStatus} />
          <List.Cell style={{ width: 200 }} label={__(T.misc.style)} value={firstItem.product.style?.value} />
          <List.Cell style={{ width: 200 }} label={__(T.misc.color)} value={firstItem.product.color?.value} />
          <List.Cell style={{ width: 200 }} label={__(T.misc.size)} value={firstItem.product.size?.value} />
          <List.Cell style={{ width: 200 }} label={__(T.misc.items)} value={`${items.length}`} />

          {checkboxHandleChangeGroup && (
            <Box style={{ alignItems: 'center' }}>
              {checkboxLabelGroup && (
                <Box height={24} style={{ fontWeight: 700, fontSize: 16, marginBottom: 5 }}>
                  {checkboxLabelGroup}
                </Box>
              )}
              <Box>
                <CheckBoxStyle
                  type="checkbox"
                  style={{ padding: 10, verticalAlign: 'middle' }}
                  id={`group_${firstItem.id}`}
                  value={items.map((item) => item.id)}
                  onClick={(event: any) => {
                    event.stopPropagation()
                  }}
                  onChange={(event: any) => {
                    checkboxHandleChangeGroup(event)
                  }}
                  defaultChecked={false}
                  checked={items.every((item) => checkedIds.includes(item.id))}
                  disabled={disabled}
                />
              </Box>
            </Box>
          )}
        </RowItemGrouped>
        {expanded &&
          items.map((item) => (
            <RowItem>
              <List.Cell style={{ width: 200 }} label={__(T.misc.upc)} value={item.upc} />
              <List.Cell style={{ width: 200 }} label={__(T.misc.status)} value={item.changeStatus} />
              <List.Cell style={{ width: 200 }} label={__(T.misc.style)} value={item.product.style?.value} />
              <List.Cell style={{ width: 200 }} label={__(T.misc.color)} value={item.product.color?.value} />
              <List.Cell style={{ width: 200 }} label={__(T.misc.size)} value={item.product.size?.value} />
              <List.Cell
                style={{ width: 200 }}
                label={__(T.misc.certilogo)}
                value={item.itemIdentifiers.find((idf) => idf.identifierType === 'CertilogoIdentifier')?.code}
              />
              {checkboxHandleChange && (
                <Box style={{ alignItems: 'center' }}>
                  {checkboxLabel && (
                    <Box height={24} style={{ fontWeight: 700, fontSize: 16, marginBottom: 5 }}>
                      {checkboxLabel}
                    </Box>
                  )}
                  <Box>
                    <CheckBoxStyle
                      type="checkbox"
                      style={{ padding: 10, verticalAlign: 'middle' }}
                      id={item.id}
                      value={item.id}
                      onClick={(event: any) => {
                        event.stopPropagation()
                      }}
                      onChange={(event: any) => {
                        checkboxHandleChange(event)
                      }}
                      defaultChecked={false}
                      checked={checkedIds.includes(item.id)}
                      disabled={disabled}
                    />
                  </Box>
                </Box>
              )}
            </RowItem>
          ))}
      </>
    )
  }
}

const CheckBoxStyle = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 10px;
  transform: scale(1.5);
  :focus {
    outline: black;
  }
`
const RowItem = styled(Box)`
  flex-direction: row;
  align-items: center;
  padding: 20px 30px;
  /* border: 2px solid #edecec; */
  min-height: 103px;
  margin-bottom: 13px;
  margin-left: 26px;
  cursor: pointer;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`
const RowItemGrouped = styled(Box)`
  flex-direction: row;
  align-items: center;
  padding: 20px 30px;
  min-height: 103px;
  margin-bottom: 13px;
  margin-top: 13px;
  cursor: pointer;
  box-sizing: border-box;
  background: rgb(231, 231, 231);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`
