export const RegisteredRoutes = {
  '/': { component: require('./Dashboard').default },
  '/tag-info': { component: require('./TagInfo/TagInfo').default },
  '/encoding': { component: require('./Encoding/Encoding').default },
  '/encoding/verify': { component: require('./Encoding/Verify').default },
  '/inbound': { component: require('./Inbound/CustomInbound').default },
  '/inbound/:configCode': { component: require('./Inbound/CustomInbound').default },
  '/custom/inbound/:configCode/create': { component: require('./CustomInbound/CustomInboundCreate').default },
  '/custom/inbound/:configCode/reading/:parcelCode': {
    component: require('./CustomInbound/CustomInboundReading').default,
  },
  '/inbound/shipments/:configCode': { component: require('./Inbound/CustomInboundShipment').default },
  '/inbound/:configCode/reading/:parcelCode': { component: require('./Inbound/InboundReading').default },
  '/inbound/gate/shipments/:configCode': { component: require('./Inbound/CustomGateInboundShipment').default },
  '/inbound/gate/shipments/:configCode/reading/:ddtCode': {
    component: require('./Inbound/CustomGateInboundShipmentReading').default,
  },
  '/inbound/stesi/shipments/:configCode': { component: require('./Inbound/CustomStesiDdtInbound').default },
  '/inbound/stesi/shipments/:configCode/reading/:ddtCode': {
    component: require('./Inbound/CustomStesiDdtInboundShipmentReading').default,
  },
  '/inbound/:configCode/samplesReading/:parcelCode': {
    component: require('./Inbound/CustomCampionarioInboundReading').default,
  },
  '/inbound/gate/:configCode/reading/:parcelCode': { component: require('./Inbound/CustomGateInboundReading').default },
  '/outbound/gate/:configCode/reading/:parcelCode': {
    component: require('./Outbound/CustomGateOutboundReading').default,
  },
  '/inbound/:configCode/:shippingCode/parcels': { component: require('./Inbound/CustomInboundShipmentDetail').default },
  '/inbound/samples/:configCode': { component: require('./Inbound/CustomCampionarioInbound').default },
  '/outbound': { component: require('./Outbound/CustomOutbound').default },
  '/outbound/create': { component: require('./CustomOutbound/CustomOutboundCreate').default },
  '/outbound/:configCode/create': { component: require('./CustomOutbound/CustomOutboundCreate').default },
  '/outbound/:configCode': { component: require('./Outbound/CustomOutbound').default },
  '/outbound/:configCode/reading': { component: require('./CustomOutbound/CustomOutboundReading').default },
  '/outbound/:configCode/reading/:parcelCode': { component: require('./CustomOutbound/CustomOutboundReading').default },
  '/outbound/:configCode/detail/:parcelCode': {
    component: require('./CustomOutbound/CustomOutboundParcelDetail').default,
  },
  '/custom/outbound/appesi/:configCode': { component: require('./Outbound/CustomOutboundAppesi').default },
  '/packinglist/detail': {
    component: require('./PackingList/PackingListDetail').default,
  },
  '/packinglist': {
    component: require('./PackingList/PackingList').default,
  },
  '/packinglist/create': {
    component: require('./PackingList/PackingListCreate').default,
  },
  '/packinglist/preview': {
    component: require('./PackingList/PackingListPreviewDetail').default,
  },
  '/packinglist/row-detail/:shippingCode': {
    component: require('./PackingList/PackingListRowDetail').default,
  },
  '/shippingddt/list': {
    component: require('./Ddt/DdtList').default,
  },
  '/shippingddt': {
    component: require('./Ddt/Ddt').default,
  },
  '/shippingddt/create': {
    component: require('./Ddt/DdtCreate').default,
  },
  '/shippingddt/update/:itemId': {
    component: require('./Ddt/DdtUpdate').default,
  },
  '/shippingddt/pl-row-detail/:shippingCode': {
    component: require('./Ddt/DdtPackingListRowDetail').default,
  },
  '/transfer-to-zone': { component: require('./TransferToZone/CustomTransferToZone').default },
  '/transfer-to-zone/reading': { component: require('./TransferToZone/TransferToZoneReading').default },
  '/test': { component: require('./Test').default },
  '/change-code': { component: require('./CustomChangeCode/CustomChangeCodeList').default },
  '/change-code/request': { component: require('./CustomChangeCode/CustomChangeCodeRequest').default },
  '/change-code/encode': { component: require('./Encoding/Encoding').default },
  '/change-choice': { component: require('./CustomChangeChoice/CustomChangeChoiceCreate').default },
  '/change-bay': { component: require('./CustomChangeBay/CustomChangeBay').default },
  '/repair': { component: require('./CustomRepair/CustomRepair').default },
  '/items-list/:operationCode': { component: require('./SimpleItemsList/SimpleItemsListReading').default },
  '/change-tag': { component: require('./CustomChangeTag/CustomChangeTag').default },
  '/quality-control/:configCode': { component: require('./CustomQualityControl/CustomQualityControlCreate').default },
  '/print-tag-envelope/:configCode': { component: require('./PrintTagEnvelope/PrintTagEnvelopeCreate').default },
  '/print-tag-envelope/:configCode/print': { component: require('./PrintTagEnvelope/PrintTagEnvelope').default },
  '/print-tag-envelope/:configCode/reprint': { component: require('./PrintTagEnvelope/RePrintTagEnvelope').default },
  '/change-code/management': { component: require('./ChangeCodeManagement/ChangeCodeManagement').default },
  '/change-code/management/association': {
    component: require('./ChangeCodeManagement/ChangeCodeManagementVariant').default,
  },
  '/change-code/approval': { component: require('./ChangeCodeApproval/ChangeCodeApproval').default },
  '/change-code/approval/association/:orderCode': {
    component: require('./ChangeCodeApproval/ChangeCodeApprovalAssociation').default,
  },
}

export const PublicRoutes = {
  '/login': { component: require('./Login').default },
}

export type Routes = keyof typeof RegisteredRoutes | keyof typeof PublicRoutes

export interface PageParams {
  operation?: any
}
