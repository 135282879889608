import React, { Component } from 'react'
import { Page, TagCounter, AntennaButton, ItemList, Box, Button, Spacer, BoxedSwitch, Icons } from 'components'
import { TmrItem } from 'api/types'
import { getLocationState, getMatchParams } from 'shared/router'
import { showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import SimpleItemsListProvider from './SimpleItemsListProvider'
import RfidReader from 'shared/RfidReader'

interface State {
  items: TmrItem[]
  deleteMode?: boolean
}

export default class TagInfo extends Component<{}, State> {
  operationCode = getMatchParams(this.props).operationCode

  state: State = {
    items: [],
    deleteMode: false,
  }

  onItemDecoded = (item) => {
    if (item && item.changeStatus !== 'NONE' && this.operationCode === 'change-code-request') {
      //ignore inserting item
      showToast({
        title: __(T.titles.change_code),
        description: __(T.error.item_in_requested_state, { epc: item.epc, state: item.changeStatus }),
        status: 'error',
      })
      return
    }

    if(item && this.operationCode === 'change-code-request' && item.itemRepairState !== null && item.itemRepairState !== 'DONE'){
      showToast({
        title: __(T.titles.change_code),
        description: __(T.error.change_code_for_repair_not_allowed),
        status: 'error',
      })
      return
    }

    const { items } = this.state
    items.unshift(item)
    this.setState({ items })

  }

  clear = () => this.setState({ items: [] })

  executeMainAction = async () => {
    try {
      await SimpleItemsListProvider.getMainAction(this.operationCode, this.state.items, getLocationState(this.props))
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  executeSecondaryAction = async () => {
    try {
      await SimpleItemsListProvider.getSecondaryAction(
        this.operationCode,
        this.state.items,
        getLocationState(this.props)
      )
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  executeTertiaryAction = async () => {
    try {
      await SimpleItemsListProvider.getTertiaryAction(
        this.operationCode,
        this.state.items,
        getLocationState(this.props)
      )
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  onItemDeletePress = (item: TmrItem) => {
    const itemIdentifiers = item.itemIdentifiers.map((id) => id.code)
    RfidReader.removeTags(itemIdentifiers)
    this.setState({ items: this.state.items.filter((itm) => itm.id !== item.id) })
  }

  onDeleteToggle = (checked: boolean) => {
    this.setState({ deleteMode: checked })
  }

  showDeleteOption = () => {
    return this.operationCode === 'change-code-request'
  }


  render() {
    const { items, deleteMode } = this.state
    const title = SimpleItemsListProvider.getPageTitle(this.operationCode)
    const mainButtonLabel = SimpleItemsListProvider.getMainActionButtonLabel(this.operationCode)
    const secondaryButtonLabel = SimpleItemsListProvider.getSecondaryActionButtonLabel(this.operationCode)
    const tertiaryButtonLabel = SimpleItemsListProvider.getTertiaryActionButtonLabel(this.operationCode)
    const onBackPress = SimpleItemsListProvider.getOnBackPressAction(this.operationCode)
    const hideRowParcelCode = SimpleItemsListProvider.getHideRowParcelCode(this.operationCode)
    const header = SimpleItemsListProvider.getPageHeader(this.operationCode, getLocationState(this.props))

    return (
      <Page
        title={title}
        enableEmulation
        onBackPress={onBackPress}
        header={header}
        headerRight={
          this.showDeleteOption() ? (
            <BoxedSwitch icon={<Icons.Trash fill="black" />} onChange={this.onDeleteToggle} check={deleteMode} />
          ) : undefined
        }
      >
        <Page.Sidebar>
          <TagCounter detected={items.length} />
          <AntennaButton onClear={this.clear} onItemDecoded={this.onItemDecoded} hideClear={items.length === 0} />
          <Box flex />
          {tertiaryButtonLabel && (
            <>
              <Button title={tertiaryButtonLabel} onClick={this.executeTertiaryAction} variant={'secondary'} />
              <Spacer />
            </>
          )}
          {secondaryButtonLabel && (
            <>
              <Button title={secondaryButtonLabel} onClick={this.executeSecondaryAction} variant={'secondary'} />
              <Spacer />
            </>
          )}
          {<Button title={mainButtonLabel} onClick={this.executeMainAction} />}
        </Page.Sidebar>

        <Page.Content>
          <ItemList
            items={items}
            hideRowParcelCode={hideRowParcelCode}
            onDeleteRowCallback={deleteMode ? this.onItemDeletePress : undefined}
          />
        </Page.Content>
      </Page>
    )
  }
}
