import api, { responseErrorCheck } from 'api/api'
import OutboundShipments from 'api/OutboundShipments'
import {
  GroupedShipmentProduct,
  ShipmentParcel,
  ShipmentParcelItem,
  TmrItem,
  TmrPlace,
  TmrProduct,
  TmrZone,
} from 'api/types'
import { OutboundConfig } from 'shared/RemoteConfig'
import ShipmentProvider from 'ShipmentProvider'
import Items from 'api/Items'

export default class CustomShipmentProvider {

  static generateParcel(
    orderCode: string,
    configuration: OutboundConfig,
    choice?: string,
    tubful?: string,
    madeForCode?: string,
    placeCode?: string,
    phaseCode?: string,
    replicaOrder?: string,
    tone?: string,
    itemId?: string
  ) {
    const data: any = {
      orderCode: orderCode,
      configuration: configuration.id,
    }
    if (placeCode) data.placeCode = placeCode
    if (choice || tubful || replicaOrder || madeForCode || phaseCode || tone) data.parcelAttributes = {}
    if (choice) data.parcelAttributes.choice = choice
    if (tubful) data.parcelAttributes.tubful = tubful
    if (madeForCode) data.parcelAttributes.madeFor = madeForCode
    if (phaseCode) data.parcelAttributes.phase = phaseCode
    if (replicaOrder) data.parcelAttributes.replicaOrder = replicaOrder
    if (tone != null) data.parcelAttributes.tone = tone
    if (itemId) data.itemId = itemId
    return api.post<{ parcel: any; destinationPlace: TmrPlace }>(`shipping/destination`, data).then(responseErrorCheck)
  }

  static async fetchOutboundShipmentParcels(parcelCode?: string, defaultShipmentParcel?: ShipmentParcel) {
    if (!parcelCode && !defaultShipmentParcel) throw new Error('No parcel found')

    if (!parcelCode && defaultShipmentParcel)
      return {
        outboundShipmentParcel: defaultShipmentParcel,
        checkListType: 'ITEMS' as 'UPCS' | 'ITEMS' | 'TAGS',
        groupedProducts: defaultShipmentParcel ? this.initializeGroupedProducts([defaultShipmentParcel]) : [],
      }

    const outboundShipmentParcels = parcelCode
      ? (await OutboundShipments.searchDetail({ parcelCodes: [parcelCode] })) ?? []
      : []
    const checkListTypes = ShipmentProvider.getCheckListTypes(outboundShipmentParcels)

    if (outboundShipmentParcels.length === 0) throw new Error('No parcel found')

    if (checkListTypes.length > 1) throw new Error('Number of checklists error')

    const checkListType = checkListTypes.length === 1 ? checkListTypes[0] : 'ITEMS'
    const groupedProducts = this.initializeGroupedProducts(outboundShipmentParcels)
    return { outboundShipmentParcel: outboundShipmentParcels[0], checkListType, groupedProducts }
  }

  static initializeGroupedProducts(shipmentParcels: ShipmentParcel[]) {
    if (!shipmentParcels || shipmentParcels.length === 0) return []

    const groupedProducts: GroupedShipmentProduct[] = []
    shipmentParcels.forEach((parcel) => {
      parcel.expectedItems.forEach((itm) => {
        const groupedProductIndex = groupedProducts.findIndex((prod) => prod.product.code === itm.productCode)
        if (groupedProductIndex >= 0) {
          return
        }
        groupedProducts.push({
          product: ShipmentProvider.getParcelProduct(itm.productCode, shipmentParcels, itm.product ?? undefined),
          detected: 0,
          unexpected: 0,
          expected: ShipmentProvider.getExpectedProductCount(itm.productCode, shipmentParcels),
        })
      })
      parcel.expectedUpcs.forEach((elem) => {
        groupedProducts.push({
          product: ShipmentProvider.getParcelProduct(elem.upc, shipmentParcels),
          detected: 0,
          unexpected: 0,
          expected: elem.quantity,
        })
      })
    })
    return ShipmentProvider.processGroupedProducts(shipmentParcels, groupedProducts)
  }

  static processItemForItemsChecklist(
    items: TmrItem[],
    shipmentParcels: ShipmentParcel[],
    groupedProducts: GroupedShipmentProduct[],
    destinationZone?: TmrZone
  ) {
    items.forEach((itm) => {
      if (!itm || itm.id === undefined) return

      const alreadyReadItem = shipmentParcels.find((shipmentParcel) =>
        shipmentParcel.detectedItems.find((detectedItem) => detectedItem.id === itm.id)
      )

      if (alreadyReadItem) return

      const parcel = shipmentParcels.find(
        (shipmentParcel) =>
          shipmentParcel.expectedItems.find((expectedItem) => expectedItem.id === itm.id) ||
          shipmentParcel.expectedUpcs.find(
            (upc) =>
              (upc.upc === itm.upc ?? itm.product.code) &&
              shipmentParcel.detectedItems.filter((detectedItem) => detectedItem.productCode === upc.upc).length <
                upc.quantity
          )
      )
      if (parcel) {
        parcel.detectedItems.push({
          productCode: itm.upc ?? itm.product.code,
          expected: true,
          ...itm,
          // zoneCode: destinationZone?.code ?? itm.zone?.code,
          // zoneId: destinationZone?.id ?? itm.zone?.id,
        })
        parcel.detectedCounter.items++
      } else {
        const firstParcel = shipmentParcels[0]
        const checkListExist =
          firstParcel.expectedCounter.tags === 0 &&
          firstParcel.expectedCounter.items === 0 &&
          firstParcel.expectedCounter.upcs === 0
        if ((itm.upc || itm.product.code) && !itm.id) {
          firstParcel.detectedTags.push({
            tag: itm.epc,
            expected: checkListExist,
            productCode: itm.upc ?? itm.product.code,
          })
          if (!checkListExist) firstParcel.unexpectedCounter.tags++ // WARN: validate this
        } else {
          firstParcel.detectedItems.push({
            productCode: itm.upc ?? itm.product.code,
            expected: checkListExist,
            ...itm,
            // zoneCode: destinationZone.code,
            // zoneId: destinationZone.id,
          })
          if (!checkListExist) firstParcel.unexpectedCounter.items++ // WARN: validate this
        }
        if (!shipmentParcels.find((shipmentParcel) => shipmentParcel.products[itm.upc ?? itm.product.code])) {
          firstParcel.products[itm.upc ?? itm.product.code] = itm.product
        }
        //if (firstParcel.expectedCounter.items > 0) firstParcel.unexpectedCounter.items++ // WARN: validate this
        firstParcel.detectedCounter.items++
      }
      ShipmentProvider.processGroupedProducts(shipmentParcels, groupedProducts, [itm])
    })
  }

  static clearAllReceivingReadings(shippingDetails: ShipmentParcel[]) {
    shippingDetails.forEach((detail) => {
      detail.detectedUpcs = []
      detail.detectedTags = []
      detail.detectedItems = []
      detail.detectedCounter = { items: 0, upcs: 0, tags: 0 }
      detail.unexpectedCounter = { items: 0, upcs: 0, tags: 0 }
    })
    return this.initializeGroupedProducts(shippingDetails)
  }

  static customProcessGroupedProducts(
    shipmentParcels: ShipmentParcel[],
    groupedProducts: GroupedShipmentProduct[] = [],
    items?: TmrItem[]
  ) {
    if (!shipmentParcels || shipmentParcels.length === 0) return groupedProducts

    shipmentParcels.forEach((parcel) => {
      parcel.detectedItems
        .filter((detectedItem) => !items || items.find((itm) => itm.id === detectedItem.id))
        .forEach((itm) => {
          const groupedProductIndex = groupedProducts.findIndex(
            (prod) => prod.parcelCode === parcel.header.parcelCode && prod.product.code === itm.productCode
          )
          if (groupedProductIndex >= 0) {
            if (itm.expected) {
              groupedProducts[groupedProductIndex].detected++
            } else {
              groupedProducts[groupedProductIndex].unexpected++
            }
            return
          }
          groupedProducts.push({
            product: this.getCustomParcelProduct(itm.productCode, shipmentParcels),
            detected: itm.expected ? 1 : 0,
            unexpected: itm.expected ? 0 : 1,
            expected: this.getCustomExpectedProductCount(itm.productCode, shipmentParcels),
            parcelCode: parcel.header.parcelCode,
          })
        })
      parcel.detectedTags
        .filter((tagObj) => !items || items.find((itm) => itm.epc === tagObj.tag))
        .forEach((tagObj) => {
          const groupedProductIndex = groupedProducts.findIndex((prod) => prod.product.code === tagObj.productCode)
          if (groupedProductIndex >= 0) {
            if (tagObj.expected) {
              groupedProducts[groupedProductIndex].detected++
            } else {
              groupedProducts[groupedProductIndex].unexpected++
            }
            return
          }
          groupedProducts.push({
            product: this.getCustomParcelProduct(tagObj.productCode!, shipmentParcels),
            detected: tagObj.expected ? 1 : 0,
            unexpected: tagObj.expected ? 0 : 1,
            expected: this.getCustomExpectedProductCount(tagObj.productCode!, shipmentParcels),
            parcelCode: parcel.header.parcelCode,
          })
        })
    })

    shipmentParcels.forEach((parcel) => {
      parcel.groupedProducts = groupedProducts.filter((gp) => gp.parcelCode === parcel.header.parcelCode)
    })

    return groupedProducts
  }

  static async fetchItemsFromShipmentParcelItems(items: ShipmentParcelItem[]) {
    if (!items) throw new Error('Empty Checklist')

    for (const itm of items) {
      // eslint-disable-next-line no-await-in-loop
      const item = (await Items.get(itm.id)) as TmrItem
      itm.itemIdentifiers = item.itemIdentifiers
      itm.product = item.product
    }
  }

  static getCustomExpectedProductCount(productCode: string, shipmentParcels: ShipmentParcel[]) {
    return shipmentParcels.reduce((total, current) => {
      const expectedItem = current.expectedItems.filter((itm) => itm.productCode === productCode)
      return total + expectedItem.length
    }, 0)
  }

  static getCustomParcelProduct(
    productCode: string,
    shipmentParcels: ShipmentParcel[],
    defaultProduct?: TmrProduct
  ): any {
    return (
      shipmentParcels.find((parcel) => productCode in parcel.products)?.products[productCode] ??
      defaultProduct ?? { code: productCode }
    )
  }
}
