export default {
  titles: {
    encoding: 'Encoding',
    inbound: 'Inbound',
    user_info: 'Info Utente',
    settings: 'Impostazioni',
    reset_daily_items: 'Reset items giornalieri?',
    last_parcels: 'Ultimi colli',
    last_packing_lists: 'Ultimi packing list',
    last_orders: 'Ultimi Ordini',
    last_ddts: 'Ultimi DDT',
    packing_list: 'Packing List',
    shipping_ddt: 'DDT',
    change_choice: 'Cambio Scelta',
    change_bay: 'Cambio Campata',
    change_code: 'Cambio Codice',
    approve_change_code: 'Approva Cambio Codice',
    coming_soon: 'Coming Soon',
    repairs: 'Riparazioni',
    select_item: 'Seleziona item',
    select_workstation: 'Seleziona postazione',
    select_language: 'Seleziona lingua',
    transfer_to_zone: 'Trasferimento a Zona',
    support: 'Supporto',
    select_printer: 'Seleziona stampante',
    number_prints: 'Numero di stampe',
    received_parcels: 'Colli Ricevuti',
    to_receive_parcels: 'Colli da Ricevere',
    in_inbound_parcels: 'Colli in Inbound',
    change_tag: 'Cambio Tag',
    update_change_code_request: 'Aggiorna Richiesta di Cambio Codice',
    samples_inbound: 'Inbound Campionario',
    cancel_parcel: 'Annulla Collo',
    quality_control: 'Controllo Qualità',
    quality_control_stireria: 'Controllo Qualità Stireria',
    add: 'Aggiungi',
    print_tag_envelope_main: 'Stampa Etichette Busta',
    print_tag_envelope: 'Stampa Etichette',
    reprint_tag_envelope: 'Ristampa Etichette',
    stealth_order: 'Commessa Stealth',
    change_code_management: 'Gestione Cambio Codice',
  },
  fields: {
    current_location: 'Posizione Attuale',
    identified_item: 'Item Identificato',
    tubful_code: 'Codice Vascata',
    code: 'Codice',
    description: 'Descrizione',
    ignored_items: 'Item ignorati',
    error_items: 'Item in errore',
    warning_items: 'Item in warning',
    flaw_reason: 'Causale Difetto',
    quality_control: 'Controllo qualità',
    tone: 'Tono',
    made_for: 'Made For',
    change_status: 'Stato cambio codice',
    tom: 'TOM',
    supplier: 'Fornitore',
    total_parcels: 'Totale colli nel DDT',
    total_lot_items: 'Numero totale di items nel lotto',
    total_lot_items_check: 'Numero totale di capi da controllare',
    total_lot_items_update: 'Numero totale di capi che saranno aggiornati',
    variant_style: 'Stile Variante',
    variant_color: 'Colore Variante',
  },
  misc: {
    save: 'Salva',
    confirm: 'Conferma',
    cancel: 'Annulla',
    close: 'Chiudi',
    user: 'Utente',
    workstation: 'Postazione',
    username: 'Username',
    name: 'Nome',
    password: 'Password',
    login: 'Login',
    encoding: 'Encoding',
    ean_code: 'Codice EAN',
    certilogo: 'Certilogo',
    topfly_order: 'Ordine TopFly',
    production_order: 'Ordine di produzione',
    enter_the_necessary_fields: 'Inserisci i campi necessari per iniziare l’operazione',
    associated_items: 'Item associati',
    associate: 'Associa',
    verify: 'Verifica',
    reading: 'In lettura',
    association: 'Associazione',
    waiting: 'In attesa',
    writing: 'Scrittura',
    retry: 'Riprova',
    writing_association: 'Scrittura e Associazione',
    confirmed: 'Confermato',
    unable_to_write: `Impossibile scrivere il tag: {{code}} per il prodotto: {{productCode}}`,
    unable_to_get_next_epc: `Impossibile ottenere il prossimo epc del prodotto: {{productCode}}`,
    wrong_next_epc: `Next epc errato: {{epc}}`,
    unable_to_connect_to_workstation: 'Impossibile connettersi alla postazione',
    tag_info: 'Tag Info',
    inbound: 'Inbound',
    outbound: 'Outbound',
    stop: 'Ferma',
    start: 'Avvia',
    pause: 'Pausa',
    clear: 'Clear',
    tag_encoded_today: 'Tag Encodato oggi',
    ready_to_receive: 'Pronto per ricezione',
    change_password: 'Cambia password',
    logout: 'Effettua logout',
    are_you_sure_to_logout: 'Vuoi procedere con il logout?',
    settings: 'Impostazioni',
    roles: 'Ruoli',
    email: 'Email',
    place: 'Luogo',
    items: 'Item',
    description: 'Descrizione',
    color: 'Colore',
    newColor: 'Nuovo Colore',
    madein: 'Made In',
    size: 'Taglia',
    newSize: 'Nuova Taglia',
    total_reads: 'LETTURE TOTALI',
    save_to_list: 'Salva nella lista',
    destination: 'Destinazione',
    destinationType: 'Tipologia Destinazione',
    origin: 'Origine',
    new_parcel: 'Nuovo Collo',
    new_packing_list: 'Nuovo Packing List',
    create_packing_list: 'Crea Packing List',
    add_packing_list: 'Aggiungi Packing List',
    preview_packing_list: 'Anteprima Packing List',
    associated_packing_list: 'Packing List associate',
    new_ddt: 'Nuovo DDT',
    send_ddt: 'Invia DDT',
    confirm_ddt: 'Conferma DDT',
    status: 'Stato',
    parcel: 'Collo',
    parcels: 'Colli',
    expected_parcels: 'Colli Attesi',
    confirmed_parcels: 'Colli Rilevati',
    shipment: 'Commessa',
    creation_date: 'Data Creazione',
    year: 'Anno',
    search: 'Cerca',
    detail: 'Dettaglio',
    quantity: 'Quantità ({{quantity}})',
    style: 'Stile',
    material: 'Materiale',
    shipment_code_auto: 'Codice Spedizione (auto)',
    parcel_code_auto: 'Codice Collo (auto)',
    next: 'Avanti',
    parcels_to_receive: 'Colli da ricevere',
    shipments_to_receive: 'Spedizioni da ricevere',
    ddt_to_receive: 'Ddt da ricevere',
    save_parcel: 'Salva Collo',
    save_parcels: 'Salva Colli',
    confirm_parcel: 'Conferma Collo',
    confirm_parcels: 'Conferma Colli',
    cancel_parcel: 'Annulla Collo',
    image_missing: 'IMMAGINE MANCANTE',
    loading: 'Caricamento...',
    choiche: 'Scelta',
    unknown_product: 'Prodotto sconosciuto',
    unknown_tag: 'Tag sconosciuto',
    Illegal_request_state: 'Stato cambio codice errato {{changeStatus}}',
    invalid_tag: 'Tag non valido',
    stock: 'Stock',
    valid: 'Valido',
    unknown: 'Unknown',
    actions: 'Azioni',
    print: 'Stampa',
    upload: 'Carica',
    reprint: 'Ristampa',
    product: 'Prodotto',
    products: 'Prodotti',
    report_repair: 'Segnala Riparazione',
    reason: 'Motivazione',
    request_code_change: 'Richiedi Cambio Codice',
    update_request: 'Aggiorna Richiesta',
    enable_workstation_emulation: 'Abilita emulazione Workstation',
    filter: 'Filtro',
    language: 'Lingua',
    warning: 'Warning',
    success: 'Success',
    tubful: 'Vascata',
    info: 'Info',
    checklist: 'Checklist',
    optional: 'Opzionale',
    requested_by: 'Richiesto da',
    pending_request: 'Richieste attive ({{count}})',
    phone: 'Telefono',
    printer: 'Stampante',
    upc: 'UPC',
    model_part_color_size: 'Modello / Parte / Colore / Taglia',
    confirm_shipment: 'Conferma Spedizione',
    receive: 'Ricevi',
    delete_association: 'Elimina Associazione',
    refresh: 'Ricarica',
    ddt_code: 'Codice DDT',
    pdf_name: 'Nome PDF',
    choose_pdf: 'Scegli PDF',
    alarm: 'Allarme',
    show_all: 'Mostra tutti',
    client_code: 'Codice cliente',
    nar: 'Numero autorizzazione reso',
    ddt: 'DDT',
    ddt_date: 'Data DDT',
    ddt_state: 'Stato DDT',
    ddt_origin: 'Origine DDT',
    ddt_destination: 'Destinatione DDT',
    ddt_user: 'DDT Confirmed By',
    ddt_id: 'ID DDT',
    confirm_lot: 'Conferma Lotto',
    confirm_variant_lot: 'Conferma Variante Lotto',
    season: 'Stagione',
    phase: 'Fase',
    requestedPhase: 'Fase richiesta',
    executionPhase: 'Fase di Esecuzione',
    packing_list_id: 'ID Packing List',
    cancel_packing_list: 'Annulla Packing List',
    packing_list_date: 'Data Packing List',
    operation: 'Operazione',
    include: 'Includi',
    select: 'Seleziona',
    select_all: 'Seleziona tutti',
    unselect_all: 'Deseleziona tutti',
    madeFor: 'Made For',
    america: 'America',
    utah: 'Utah',
    quebec: 'Quebec',
    undefined: 'Undefined',
    manage: 'Gestione',
    manage_change_code: 'Gestione Cambio Codice',
    approve_change_code: 'Approva Cambio Codice',
    approve: 'Approva',
  },
  error: {
    error: 'Errore',
    forbidden: 'Forbidden',
    not_found: 'Not Found',
    unauthorized: 'Unauthorized',
    too_many_tags_read: 'Hai letto troppi tag (attesi {{exptected}} letti {{read}})',
    too_many_checklist_types: 'Più di un tipo di checklist trovato. Assicurati di selezionare colli con checklist dello stesso tipo.',
    rfid_discrepancies_found: 'Rilevate discrepanze RFID, rimuovere le letture e riprovare o confermare con discrepanze',
    certilogo_not_found: 'Certilogo "{{code}}" non trovato',
    ean_not_found: 'EAN "{{code}}" non trovato',
    production_order_not_found: 'Ordine di Produzione "{{code}}" non trovato',
    no_inbound_zones: 'Nessuna zona di Inbound',
    no_operation_config: 'Configurazione operazione non trovata',
    not_supported_reading_mode: 'Modalità di lettura non supportata',
    undefined_operation_config: 'La configurazione non è definita',
    certilogo_already_encoded: 'Certilogo "{{code}}" già encodato',
    certilogo_already_associated: 'Certilogo "{{code}}" già associato',
    tag_not_found: 'Tag non trovato',
    tag_unassociated: 'Tag non associato',
    tag_type_unexpected: 'Non è previsto un tag di questo tipo',
    tag_already_associated: 'Il tag è già associato ad un altro item',
    tags_not_associated: 'Uno o più tag non è associato ad alcun item',
    tags_mismatch: 'Il tag è associato ad un altro item',
    destination_required: 'Il campo destinazione è obbligatorio',
    workstation_could_not_be_started: 'La workstation non può essere avviata',
    authentication_failed: 'Autenticazione fallita',
    destination_field_required: 'Campo destinazione obbligatorio',
    too_many_products_read: 'Letti troppi prodotti',
    choice_field_required: 'Campo Scelta obbligatorio',
    printer_not_found: 'Stampante non trovata',
    sizes_required: 'Sono richieste tutte oppure nessuna delle dimensioni!',
    unable_to_connect_to_rfid_reader: 'Impossibile avviare il reader. Verifica che il reader sia acceso',
    repair_reason_required: 'La motivazione della riparazione è obbligatoria',
    repair_item_already_pending: 'Riparazione già richiesta per questo prodotto',
    parcel_already_received: 'Collo già ricevuto',
    items_in_error_found: 'Trovati prodotti in errore',
    virgin_tags_found: 'Tag vergini trovati',
    virgin_tags_found_check_required: 'Sono stati trovati dei tag vergini.\n Controllo richiesto.',
    item_not_in_inbound: 'Prodotto non in inbound',
    item_not_in_stock: 'Prodotto non a stock',
    item_in_another_parcel: 'Prodotto in un altro collo',
    item_in_another_production_order: 'Prodotto in un altro ordine di produzione',
    item_in_change_code: 'Prodotto in cambio codice',
    item_in_execution_phase: 'Prodotto in attesa del cambio codice',
    no_more_execution_phase: 'Non ci sono altre fasi modificabili',
    item_with_error_choice: 'Prodotto con scelta non valida',
    item_with_error_flaw: 'Prodotto con difetto non valido',
    item_with_enough_printing: 'Prodotto con numero di stampe insufficiente',
    item_with_error_cq: 'Prodotto con Controllo Qualità mancante',
    item_with_error_rpac: 'Prodotto con identificativo RPAC mancante',
    item_with_error_madefor: 'MadeFor del prodotto diverso da quello del collo',
    item_already_in_repair: 'Prodotto già in un processo di riparazione',
    production_order_or_certilogo_required: 'Almeno uno tra commessa di produzione o certilogo è obbligatorio',
    certilogo_required: 'Certilogo è obbligatorio',
    invalid_print_quantity: 'Quantità di stampa non valida',
    choice_or_tone_or_madefor_required: 'Almeno uno tra scelta, tono o made for è obbligatorio',
    flaw_reason_required: 'Causale difetto obbligatoria per la scelta selezionata',
    uhf_tag_not_found: 'Tag UHF non trovato',
    discrepancy_message: 'Trovati prodotti con discrepanze. Impossibile confermare',
    parcel_not_found: 'Collo non trovato',
    shipment_not_found: 'Spedizione non trovata',
    quality_control_info_not_found: 'Impossibile scaricare informazioni relative al controllo qualità',
    no_parcels_for_ddt_found: 'Impossibile trovare i colli per il DDT {{ddtCode}}',
    ddtOk_ddtDateKo: 'Se il Codice DDT è valorizzato, dovrebbe essere valorizzato anche la Data DDT',
    ddtko_ddtDateOk: 'Se la Data DDT è valorizzato, dovrebbe essere valorizzato anche il Codice DDT',
    generic_error: 'Errore generico',
    cannot_build_inbound_request: `Impossibile comporre la richiesta dell'inbound`,
    quality_control_invalid: 'Il tipo di Controllo Qualità non è valido',
    topfly_order_required: "L'Ordine TopFly è obbligatorio",
    ddt_required: 'DDT è obbligatorio',
    ddt_code_required: 'Il codice DDT è obbligatorio',
    ddt_date_required: 'La data del DDT è obbligatoria',
    print_tag_envelope_item_wait_not_found: "Il tempo di attesa di ristampa dell'item non è stato configurato sul place",
    ddt_pdf_required: 'Il PDF è obbligatorio',
    file_name_required: 'Nome PDF è obbligatorio',
    id_ddt_required: 'ID DDT è obbligatorio',
    qc_zone_not_found: 'Zona Controllo Qualità non trovata',
    print_tag_envelope_oper_not_chosen: 'Scegliere quale operazione eseguire: Stampa, Ristampa',
    print_tag_envelope_printer_not_chosen: 'Scegliere la stampante',
    print_tag_envelope_print_number_not_chosen: 'Scegliere il numero di stampe',
    print_tag_envelope_print_not_reprint: "Item già stampato, selezionare l'operazione di Ristampa",
    print_tag_envelope_reprint_not_print: "Item non ancora stampato, selezionare l'operazione di Stampa",
    check_your_writing_capabilities: "Verifica i permessi per la scrittura dell'EPC",
    tag_is_different_from_previously_read_tag: 'Il tag è diverso dal tag letto precedentemente',
    seasons_not_78: 'La stagione DEVE essere una delle seguenti: AW23/SS23',
    unable_to_get_tid: `Impossibile leggere tid dal tag`,
    cannot_fetch_choices: `Impossibile scaricare scelte`,
    cannot_find_desired_choice: 'Impossibile trovare la scelta desiderata',
    tone_field_required: 'Campo Tono richiesto',
    production_order_required: 'Ordine di Produzione richiesto',
    item_not_selected: 'Nessun item selezionato',
    item_in_requested_state: 'Item {{epc}} è in stato {{state}}',
    ddt_with_different_madeFor: 'DDT con MadeFor diverso',
    change_code_for_repair_not_allowed: 'Non è consentito il cambio codice per item in riparazione',
  },
  messages: {
    no_workstations_found: 'Nessuna Postazione trovata',
    no_workstation_selected: 'Nessuna Postazione selezionata',
    are_you_sure_to_reset_daily_items: 'Sei sicuro di voler resettare gli item giornalieri?',
    bring_an_item_to_antenna: "Avvicina un prodotto all'antenna",
    press_start_to_read: 'Premi Start per iniziare \na leggere i prodotti',
    paste_comma_separeted_tags: 'Incolla i tags separati da virgola e premi Enter...',
    connection_workstation_progress: 'Connessione alle antenne in corso...',
    print_success: 'Etichetta inviata alla stampante con successo',
    describe_repair_reason: 'Descrivi la motivazione per la riparazione...',
    operation_success: 'Operazione completata con successo',
    outbound_success: 'Outbound completata con successo',
    inbound_success: 'Inbound completata con successo',
    transfer_to_zone_success: 'Trasferimento a Zona completato con successo',
    list_empty: 'Lista vuota',
    looks_like_anything_in_list: 'Sembra che la tua lista sia vuota',
    insert_bay_code_description: 'Inserisci codice e descrizione della campata',
    destination_required: 'Destinazione richiesta',
    are_you_sure_to_cancel_parcel: 'Sei sicuro di voler annullare il collo?',
    cancel_success: 'Collo annullato con successo',
    select_on_or_more_parcels: 'Seleziona uno o più colli',
    pl_cancel_success: 'Packing List annullato con successo',
    file_upload_success: 'File caricato con successo',
    waiting_next_print: 'Attesa per la prossima stampa: ',
    waiting_ddt_creation: 'Creazione del DDT e download del PDF in corso.\n Attendere...',
    waiting_download_pdf: 'Download del PDF in corso.\n Attendere...',
    item_is_not_in_pending: `Item non è in stato PENDING`,
    cq_in_progress: 'Richiesta CQ inviata correttamente in coda. Attendere il completamento...',
    stealth_order_madefor: 'Item {{epc}} fa parte della Commessa Stealth. Madefor NON verrà cambiato su questo item.',
    no_item_in_requested_state: 'Non ci sono items in CHANGE_REQUESTED',
    no_item_in_change_await_state: 'Non ci sono items in CHANGE_AWAIT',
    approve_execution_phase_message: 'Sei sicuro di voler sovrascrivere le fasi di esecuzione sugli items selezionati?',
    confirm_execution_phase_message: 'Sei sicuro di voler confermare le fasi di esecuzione sugli items selezionati?',
    confirm_rejection_change_code: 'Sei sicuro di voler rifiutare la richiesta di cambio codice per gli items selezionati?',
    exec_phase_success: 'Fasi di esecuzione confermate con successo',
  },
  placeholder: {
    search_shipment_parcel: 'Cerca Commessa o Collo',
    search_product_parcel: 'Cerca Prodotto o Collo',
    search_shipment_parcel_tom: 'Cerca TOM, Collo o DDT',
    search_shipment_pl: 'Cerca Commessa o ID PL',
    search_ddt: 'Cerca DDT',
    print_quantity: 'Quantità di stampa',
    search_pl: 'Cerca PackingList ID',
    search_ddt_id: 'Cerca DDT ID',
    insert_ddt_code: 'Inserisci Codice DDT...',
    insert_ddt_date: 'Inserisci Data DDT...',
    insert_weight: 'Inserisci il peso...',
    notes: 'Note del Packing List',
    search_production_order: 'Cerca Ordine di Produzione',
  },
  confirm: {
    discrepancy_title: 'Collo con discrepanze',
    discrepancy_message: 'Trovate discrepanze nel collo. Sei sicuro di voler confermare?',
    change_code_title: 'Conferma Cambio Codice',
    change_code_message: 'Conferma per inviare una richiesta di cambio codice per i prodotti',
    select_choice: 'Seleziona la scelta desiderata',
    confirm_delete_association: 'Sei sicuro di voler disassociare questo prodotto?',
    confirm_shipment: 'Conferma Spedizione',
    confirm_shipment_message: 'Sei sicuro di voler confermare questa spedizione?',
    confirm_stop_gate: 'Sei sicuro di voler fermare la linea? Confermare solo in caso di emergenza',
    change_choice_title: 'Conferma Cambio scelta',
    change_choice_message: 'Conferma per aggiornare scelta, causale difetto, tono o made for',
    confirm_ttz: 'Sei sicuro di voler confermare il trasferimento a zona?',
    confirm_update_change_code: "Sei sicuro di voler confermare l'aggiornamento della richiesta di cambio codice?",
    save_parcel_readings: 'Salva letture collo',
    save_parcel_readings_message: 'Sei sicuro di voler salvare le letture parziali senza confermare il collo?',
    quality_control_title: 'Conferma Controllo Qualità',
    quality_control_message: 'Conferma per aggiornare il controllo qualità',
    create_new_packing_list: 'Conferma per creare un nuovo Packing List',
    create_new_packing_list_title: 'Creazione Packing List',
    confirm_cancel_current_packing_list: 'Annullo Packing List',
    cancel_current_packing_list: "Sei sicuro di voler confermare l'annullo della Packing List?",
    create_new_ddt: 'Conferma per creare un nuovo DDT',
    create_new_ddt_title: 'Crea DDT',
    change_items: 'Cambia Item',
    approve_items: 'Approva Item',
    change_exec_phase: 'Cambia Fase',
    confirm_exec_phases: 'Conferma Fase Esecuzione',
    confirm_reject_change_code: 'Conferma Rifiuto Cambio Codice',
    reject_change_code: 'Rifiuta',
  },
  labels: {
    draft: 'Bozza',
    ready_to_send: 'Pronto per spedizione',
    send_await: 'In attesa',
    outbound_error: 'Errore Outbound',
    sent: 'Inviato',
    in_inbound: 'In Inbound',
    receive_await: 'In attesa',
    ready_to_receive: 'Da ricevere',
    inbound_error: 'Errore Inbound',
    received: 'Ricevuto',
    in_transit: 'In transito',
    wait_for_ddt: 'In attesa del DDT',
    wait_for_cq: 'In attesa del CQ',
    completed: 'Completato',
    error_pl: 'Errore PL',
    cq_in_progress: 'In lavorazione',
    parcels_confirmed: 'Colli confremati',
    ok: 'Ok',
    confirm_error: 'Errore conferma',
    cq_error: 'Errore CQ',
    cancelled: 'Annullato',
    grade_a: 'Prima',
    grade_b: 'Primetta',
    grade_c: 'Seconda',
    grade_d: 'Fallato',
    japan: 'Japan',
    failed: 'Failed',
    toneUndefined: '0 - Rimuovi Tono',
    none: 'None',
    change_requested: 'Cambio Richiesto',
    change_await: 'Cambio in attesa',
    change_approved: 'Cambio Approvato',
    change_pending: 'Cambio in Pending',
  },
  appSystem: {
    isPLNoteModalEnabled: 'isPLNoteModalEnabled',
    enableCQ79: 'enableCQ79',
  },
}
