import React, { Component } from 'react'
import { Box, Form, Page } from 'components'
import { showToast } from 'shared/utils'
import { __, T } from 'translations/i18n'
import { navigate } from 'shared/router'
import ProductionOrders from 'api/ProductionOrders'
import CustomItems from 'api/CustomItems'
import { CustomTmrItem, GroupedVariantItems } from 'api/types'
import AppStore from 'AppStore'
import ScalarinoApi from '../../api/Scalarino'

interface State {
  ordersWithChangeRequests: CustomTmrItem[]
}

export default class ChangeCodeManagement extends Component<{}> {
  state: State = {
    ordersWithChangeRequests: [],
  }

  async componentDidMount() {
    const loggedUserId = AppStore.loggedUser?.id
    if (loggedUserId) {
      const response = await CustomItems.getRequestedChangeByCurrentUser()
      const ordersWithChangeRequestsEntries = response.reduce((acc, curr) => {
        const keys: string[] = [curr.productionOrderRow?.order?.code, curr.product?.style?.value]
        const key = keys.map((k) => `${k}`).join('_')

        if (acc[key] && Array.isArray(acc[key])) {
          acc[key].push(curr)
        } else {
          acc[key] = [curr]
        }
        return acc
      }, {} as GroupedVariantItems)

      this.setState({
        ordersWithChangeRequests: Object.values(ordersWithChangeRequestsEntries).map((items) => items[0]),
      })
    }
  }

  submit = async (data) => {
    let taglie: unknown[] = []
    let colori: unknown[] = []
    const orderCode = data.orderCode?.id
    if (!orderCode) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.production_order_required),
        status: 'error',
      })
      return
    }

    const order = await ProductionOrders.get(orderCode)
    if (order.length === 0) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.production_order_not_found, { code: orderCode }),
        status: 'error',
      })
      return
    }
    const productionOrder = order[0]

    try {
      const items = await CustomItems.getChangeCodeToManage(order[0].code)
      if (items?.length === 0) {
        showToast({
          title: __(T.error.error),
          description: __(T.error.no_item_in_requested_state),
          status: 'error',
        })
        return
      }

      if (items.length > 0) {
        const modello = items[0].product.style !== undefined ? items[0].product.style.value : null
        const listaTaglie = await ScalarinoApi.getTaglie(modello)
        taglie = listaTaglie
        const listaColori = await ScalarinoApi.getColori(modello)
        colori = listaColori
      }

      navigate(
        '/change-code/management/association',
        {},
        {
          state: {
            taglie,
            colori,
            productionOrder,
          },
        }
      )
    } catch (e) {
      showToast({ title: __(T.error.error), description: e?.message ?? e, status: 'error' })
    }
  }

  render() {
    const { ordersWithChangeRequests } = this.state
    return (
      <Page title={__(T.titles.change_code_management)}>
        <Page.Content bgGrey>
          <Box flex center>
            <Form
              schema={[
                {
                  label: __(T.misc.production_order),
                  name: 'orderCode',
                  type: 'select',
                  options: ordersWithChangeRequests.map((order) => {
                    return {
                      id: order?.productionOrderRow?.order?.code || 'invalid id',
                      label: `${order?.productionOrderRow?.order?.code || 'Invalid production order code'} - ${
                        order.product?.style?.value
                      }`,
                      upc: order?.upc,
                    }
                  }),
                  config: {
                    value: 'id',
                    label: 'label',
                  },
                  focus: true,
                  required: true,
                },
              ]}
              submitText={__(T.misc.next)}
              onSubmit={this.submit}
              width={500}
            />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
