export default {
  titles: {
    encoding: 'Encoding',
    inbound: 'Inbound',
    user_info: 'User Info',
    settings: 'Settings',
    reset_daily_items: 'Reset daily items?',
    last_parcels: 'Last Parcels',
    last_packing_lists: 'Last Packing Lists',
    last_orders: 'Last Orders',
    last_ddts: 'Last DDTs',
    packing_list: 'Packing List',
    shipping_ddt: 'Shipping DDT',
    change_choice: 'Change Choice',
    change_bay: 'Change Bay',
    change_code: 'Change Code',
    approve_change_code: 'Approve Change Code',
    coming_soon: 'Coming Soon',
    repairs: 'Repairs',
    select_item: 'Select Item',
    select_workstation: 'Select workstation',
    select_language: 'Select language',
    transfer_to_zone: 'Transfer to zone',
    support: 'Support',
    select_printer: 'Select printer',
    number_prints: 'Number of prints',
    received_parcels: 'Received Parcels',
    to_receive_parcels: 'Parcels To Receive',
    in_inbound_parcels: 'In Inbound Parcels',
    change_tag: 'Change Tag',
    update_change_code_request: 'Update Change Code Request',
    samples_inbound: 'Samples inbound',
    cancel_parcel: 'Cancel Parcel',
    quality_control: 'Quality Control',
    quality_control_stireria: 'Quality Control Stireria',
    add: 'Add',
    print_tag_envelope_main: 'Print Tags Envelope',
    print_tag_envelope: 'Print Tags',
    reprint_tag_envelope: 'Reprint Tags',
    stealth_order: 'Stealth Order',
    change_code_management: 'Change Code Management',
    confirm_exec_phases: 'Confirm Execution Phases',
  },
  fields: {
    current_location: 'Current Location',
    identified_item: 'Identified Item',
    tubful_code: 'Tubful Code',
    code: 'Code',
    description: 'Description',
    ignored_items: 'Ignored Items',
    error_items: 'Error Items',
    warning_items: 'Warning Items',
    flaw_reason: 'Flaw Reason',
    quality_control: 'Quality control',
    tone: 'Tone',
    made_for: 'Made For',
    change_status: 'Change Status',
    tom: 'TOM',
    supplier: 'Supplier',
    total_parcels: 'Total parcels in DDT',
    total_lot_items: 'Total lot items',
    total_lot_items_check: 'Total items to check',
    total_lot_items_update: 'Total items to be updated',
    variant_style: 'Variant Style',
    variant_color: 'Variant Color',
  },
  misc: {
    save: 'Save',
    confirm: 'Confirm',
    cancel: 'Cancel',
    close: 'Close',
    user: 'User',
    workstation: 'Workstation',
    username: 'Username',
    name: 'Name',
    password: 'Password',
    login: 'Login',
    encoding: 'Encoding',
    ean_code: 'EAN Code',
    certilogo: 'Certilogo',
    topfly_order: 'TopFly Order',
    production_order: 'Production Order',
    enter_the_necessary_fields: 'Enter the necessary fields to start the operation',
    associated_items: 'Associated Items',
    associate: 'Associate',
    verify: 'Verify',
    reading: 'Reading',
    association: 'Association',
    waiting: 'Waiting',
    writing: 'Writing',
    retry: 'Retry',
    writing_association: 'Writing and association',
    confirmed: 'Confirmed',
    unable_to_write: `Unable to write epc tag : {{code}} for product: {{productCode}}`,
    unable_to_get_next_epc: `Unable to get next epc for: {{productCode}}`,
    wrong_next_epc: `Wrong next epc for: {{epc}}`,
    unable_to_connect_to_workstation: 'Unable to connect to workstation',
    tag_info: 'Tag Info',
    inbound: 'Inbound',
    outbound: 'Outbound',
    stop: 'Stop',
    start: 'Start',
    pause: 'Pause',
    clear: 'Clear',
    tag_encoded_today: 'Tag Encoded today',
    ready_to_receive: 'Ready to receive',
    change_password: 'Change Password',
    logout: 'Logout',
    are_you_sure_to_logout: 'You really want to log out?',
    settings: 'Settings',
    roles: 'Roles',
    email: 'Email',
    place: 'Place',
    items: 'Items',
    description: 'Description',
    color: 'Color',
    newColor: 'New Color',
    madein: 'Made In',
    size: 'Size',
    newSize: 'New Size',
    total_reads: 'TOTAL READS',
    save_to_list: 'Save to list',
    destination: 'Destination',
    destinationType: 'Destination Type',
    origin: 'Origin',
    new_parcel: 'New Parcel',
    new_packing_list: 'New Packing List',
    create_packing_list: 'Create Packing List',
    add_packing_list: 'Add Packing List',
    preview_packing_list: 'Packing List Preview',
    associated_packing_list: 'Associated Packing List',
    new_ddt: 'New DDT',
    send_ddt: 'Send DDT',
    confirm_ddt: 'Confirm DDT',
    status: 'Status',
    parcel: 'Parcel',
    parcels: 'Parcels',
    expected_parcels: 'Expected Parcels',
    confirmed_parcels: 'Detected Parcels',
    shipment: 'Shipment',
    creation_date: 'Creation Date',
    year: 'Year',
    search: 'Search',
    detail: 'Detail',
    quantity: 'Quantity ({{quantity}})',
    style: 'Style',
    material: 'Material',
    shipment_code_auto: 'Shipment code (auto)',
    parcel_code_auto: 'Parcel code (auto)',
    next: 'Next',
    parcels_to_receive: 'Parcels to receive',
    shipments_to_receive: 'Shipments to receive',
    ddt_to_receive: 'Ddt to receive',
    save_parcel: 'Save Parcel',
    save_parcels: 'Save Parcels',
    confirm_parcel: 'Confirm Parcel',
    confirm_parcels: 'Confirm Parcels',
    cancel_parcel: 'Cancel Parcel',
    image_missing: 'IMAGE MISSING',
    loading: 'Loading...',
    choiche: 'Choice',
    unknown_product: 'Unknown Product',
    illegal_request_state: 'Illegal request state {{changeStatus}}',
    unknown_tag: 'Unknown Tag',
    invalid_tag: 'Invalid Tag',
    stock: 'Stock',
    valid: 'Valid',
    unknown: 'Unknown',
    actions: 'Actions',
    print: 'Print',
    upload: 'Upload',
    reprint: 'Reprint',
    product: 'Product',
    products: 'Products',
    report_repair: 'Report Repair',
    reason: 'Reason',
    request_code_change: 'Request Code Change',
    update_request: 'Update Request',
    enable_workstation_emulation: 'Enable Workstation emulation',
    filter: 'Filter',
    language: 'Language',
    warning: 'Warning',
    success: 'Success',
    tubful: 'Tubful',
    info: 'Info',
    checklist: 'Checklist',
    optional: 'Optional',
    requested_by: 'Requested by',
    pending_request: 'Pending request ({{count}})',
    phone: 'Phone',
    printer: 'Printer',
    upc: 'UPC',
    model_part_color_size: 'Model / Part / Color / Size',
    confirm_shipment: 'Confirm Shipment',
    receive: 'Receive',
    delete_association: 'Delete Association',
    refresh: 'Refresh',
    ddt_code: 'DDT Code',
    pdf_name: 'PDF name',
    choose_pdf: 'Choose PDF',
    alarm: 'Alarm',
    show_all: 'Show All',
    client_code: 'client code',
    nar: 'Return authorization number',
    ddt: 'DDT',
    ddt_date: 'DDT Date',
    ddt_state: 'DDT State',
    ddt_origin: 'DDT Origin',
    ddt_destination: 'DDT Destination',
    ddt_user: 'DDT Confirmed By',
    ddt_id: 'ID DDT',
    confirm_lot: 'Confirm Lot',
    confirm_variant_lot: 'Confirm Variant Lot',
    season: 'Season',
    phase: 'Phase',
    requestedPhase: 'Requested Phase',
    executionPhase: 'Execution Phase',
    packing_list_id: 'Packing List ID',
    cancel_packing_list: 'Cancel Packing List',
    packing_list_date: 'Packing List Date',
    operation: 'Operaton',
    include: 'Include',
    select: 'Select',
    select_all: 'Select All',
    unselect_all: 'Unselect All',
    madeFor: 'Made For',
    america: 'America',
    utah: 'Utah',
    quebec: 'Quebec',
    undefined: 'Undefined',
    manage: 'Manage',
    manage_change_code: 'Manage Change Code',
    approve_change_code: 'Approve Change Code',
    approve: 'Approve',
  },
  error: {
    error: 'Error',
    forbidden: 'Forbidden',
    not_found: 'Not Found',
    unauthorized: 'Unauthorized',
    too_many_tags_read: 'Too many tags read (expected {{exptected}} read {{read}})',
    too_many_checklist_types: 'Too many checklist types. Make sure to select parcels with same checklist type.',
    rfid_discrepancies_found: 'RFID Discrepancies found, clear all and try again or confirm with discrepancies',
    certilogo_not_found: 'Certilogo "{{code}}" not found',
    ean_not_found: 'EAN "{{code}}" not found',
    production_order_not_found: 'Production Order "{{code}}" not found',
    no_inbound_zones: 'No Inbound zones',
    no_operation_config: 'Operation configuration not found',
    not_supported_reading_mode: 'Reading mode not supported',
    undefined_operation_config: 'Operation configuration cannot be undefined',
    certilogo_already_encoded: 'Certilogo "{{code}}" already encoded',
    certilogo_already_associated: 'Certilogo "{{code}}" already associated',
    tag_not_found: 'Tag not found',
    tag_unassociated: 'Tag unassociated',
    tag_type_unexpected: 'Unexpected tag type',
    tag_already_associated: 'Tag already associated',
    tags_not_associated: 'One or more tags are not associated',
    tags_mismatch: 'Tags mismatch',
    destination_required: 'Destination field is required',
    workstation_could_not_be_started: 'Workstation could not be started',
    authentication_failed: 'Authentication failed',
    destination_field_required: 'Destination field required',
    too_many_products_read: 'Too many products read',
    choice_field_required: 'Choice fields is required',
    printer_not_found: 'Printer not found',
    sizes_required: 'All three or none or sizes are required!',
    unable_to_connect_to_rfid_reader: 'Unable to connect to RFID Reader. Check that the device is turned On.',
    repair_reason_required: 'Repair reason is required',
    repair_item_already_pending: 'Item already marked as to repair',
    parcel_already_received: 'Parcel already received',
    items_in_error_found: 'Items in error found',
    virgin_tags_found: 'Virgin tags found',
    virgin_tags_found_check_required: 'Virgin tags were found.\nControl required.',
    item_not_in_inbound: 'Item not in inbound',
    item_not_in_stock: 'Item not in stock',
    item_in_another_parcel: 'Item in another parcel',
    item_in_another_production_order: 'Item in another production order',
    item_in_change_code: 'Item waiting for change code',
    item_in_execution_phase: 'Execution Phase error',
    no_more_execution_phase: 'No more execution phase',
    item_with_error_choice: 'Item with error choice',
    item_with_error_flaw: 'Item with error flaw',
    item_with_enough_printing: 'Item with insufficient number of printing',
    item_with_error_cq: 'Item with missing Quality Control',
    item_with_error_rpac: 'Item with missing RPAC identifier',
    item_with_error_madefor: "Item madeFor doesn't match with parcel",
    item_already_in_repair: "Item already in a repair process",
    production_order_or_certilogo_required: 'At least one of certilogo or production order is required',
    certilogo_required: 'Certilogo is required',
    invalid_print_quantity: 'Invalid Print quantity',
    choice_or_tone_or_madefor_required: 'At least one of choice or tone or made for is required',
    flaw_reason_required: 'Flaw reason is required for selected choice',
    uhf_tag_not_found: 'UHF Tag not found',
    discrepancy_message: 'Products with discrepancies were found. Confirmation forbidden',
    parcel_not_found: 'Parcel not found',
    shipment_not_found: 'Shipment not found',
    quality_control_info_not_found: 'Quality control details not found',
    no_parcels_for_ddt_found: 'No parcels for DDT {{ddtCode}} found',
    ddtOk_ddtDateKo: 'If DDT Code is set, DDT Date should be set too',
    ddtko_ddtDateOk: 'If DDT Date is set, DDT Code should be set too',
    generic_error: 'Generic error',
    cannot_build_inbound_request: 'Cannot build inbound request',
    quality_control_invalid: 'Quality Control type not valid',
    topfly_order_required: 'TopFly Order is required',
    ddt_required: 'DDT is required',
    ddt_code_required: 'DDT Code is required',
    ddt_date_required: 'DDT Date is required',
    ddt_pdf_required: 'PDF is required',
    file_name_required: 'PDF file name is required',
    id_ddt_required: 'ID DDT is required',
    qc_zone_not_found: 'Quality Control zone not found',
    print_tag_envelope_item_wait_not_found: 'Item reprint wait time not configured on this place',
    print_tag_envelope_oper_not_chosen: 'Choose which operation to perform: Print, Reprint',
    print_tag_envelope_printer_not_chosen: 'Choose the printer',
    print_tag_envelope_print_number_not_chosen: 'Choose the number of prints',
    print_tag_envelope_print_not_reprint: 'Item already printed, select the Reprint operation',
    print_tag_envelope_reprint_not_print: 'Item not printed yet, select the Print operation',
    check_your_writing_capabilities: 'Check your writing capabilities',
    tag_is_different_from_previously_read_tag: 'Tag is different from previously read tag.',
    seasons_not_78: 'Item season MUST be AW23/SS23',
    unable_to_get_tid: `Unable to read tid from tag`,
    item_is_not_in_pending: `Item is not in pending state`,
    cannot_fetch_choices: 'Cannot fetch choices',
    cannot_find_desired_choice: 'Cannot find desired choice',
    tone_field_required: 'Tone fields is required',
    production_order_required: 'Production Order is required',
    item_not_selected: 'No item selected',
    no_item_in_requested_state: 'No item in CHANGE_REQUESTED state',
    item_in_requested_state: 'Item {{epc}} is in {{state}} state',
    no_item_in_change_await_state: 'No item in CHANGE_AWAIT state',
    ddt_with_different_madeFor: 'DDT with different MadeFor',
    change_code_for_repair_not_allowed: 'Change code for item in repair process is not allowed',
  },
  messages: {
    no_workstations_found: 'No Workstations Found',
    no_workstation_selected: 'No Workstation selected',
    are_you_sure_to_reset_daily_items: 'Are you sure you want to reset the daily items?',
    bring_an_item_to_antenna: 'Bring an item close to the antenna',
    press_start_to_read: 'Press Start button to \nstart reading RFID products',
    paste_comma_separeted_tags: 'Paste comma separated tags then press return...',
    connection_workstation_progress: 'Connection to workstation in progress..',
    print_success: 'Label sent to printer with success',
    describe_repair_reason: 'Describe the reason for the repair...',
    operation_success: 'Operation successfully confirmed',
    outbound_success: 'Outbound successfully confirmed',
    inbound_success: 'Inbound successfully confirmed',
    transfer_to_zone_success: 'Transfer To Zone successfully confirmed',
    list_empty: 'List is empty',
    looks_like_anything_in_list: "Looks like you don't have anything in your list",
    insert_bay_code_description: 'Insert bay code and description',
    destination_required: 'Destination is required',
    are_you_sure_to_cancel_parcel: 'Are you sure you want to cancel the parcel?',
    cancel_success: 'Parcel successfully canceled',
    select_on_or_more_parcels: 'Select one or more parcels',
    pl_cancel_success: 'Packing List successfully canceled',
    file_upload_success: 'File successfully uploaded',
    waiting_next_print: 'Waiting time for next print: ',
    waiting_ddt_creation: 'DDT creation and PDF download in progress.\n Please wait...',
    waiting_download_pdf: 'PDF download in progress.\n Please wait...',
    cq_in_progress: 'CQ request successfully sent in queue. Wait completition...',
    stealth_order_madefor: 'Item {{epc}} has Stealth Production Order. Madefor will not be changed on this item.',
    approve_execution_phase_message: 'Are you sure you want to override execution phases on selected items?',
    confirm_execution_phase_message: 'Are you sure you want to confirm execution phases on selected items?',
    confirm_rejection_change_code: 'Are you sure you want to reject the change code request on selected items?',
    exec_phase_success: 'Execution Phase successfully confirmed',
  },
  placeholder: {
    search_shipment_parcel: 'Search Shipment or Parcel',
    search_product_parcel: 'Search Product or Parcel',
    search_shipment_parcel_tom: 'Search TOM or Shipment or DDT',
    search_shipment_pl: 'Search Shipment or PL ID',
    search_ddt: 'Search DDT',
    print_quantity: 'Print Quantity',
    search_pl: 'Search PackingList ID',
    search_ddt_id: 'Search DDT ID',
    insert_ddt_code: 'Insert DDT Code...',
    insert_ddt_date: 'Insert DDT Date...',
    insert_weight: 'Insert package weight...',
    notes: 'Insert notes for the Packing List',
    search_production_order: 'Search Production Order',
  },
  confirm: {
    discrepancy_title: 'Parcel with discrepancies',
    discrepancy_message: 'Products with discrepancies were found. Are you sure you want to confirm the parcel?',
    change_code_title: 'Confirm Code Change',
    change_code_message: 'Confirm to send the request to change the products code',
    select_choice: 'Select the desired choice',
    confirm_delete_association: 'Are you sure you want to delete this item association?',
    confirm_shipment: 'Confirm Shipment',
    confirm_shipment_message: 'Are you sure you want to confirm the current shipment?',
    confirm_stop_gate: 'Are you sure you want to stop the gate? Use this only for emergencies',
    change_choice_title: 'Confirm Change Choice',
    change_choice_message: 'Confirm to change choice, flaw reason, tone or made for',
    confirm_ttz: 'Are you sure you want to confirm the transfer to zone?',
    confirm_update_change_code: 'Are you sure you want to confirm the request to update the change code?',
    save_parcel_readings: 'Save Parcel Readings',
    save_parcel_readings_message: 'Are you sure you want to save this parcel readings without confirm?',
    quality_control_title: 'Confirm Quality Control',
    quality_control_message: 'Confirm to change quality control',
    create_new_packing_list: 'Confirm to create a new Packing List',
    create_new_packing_list_title: 'Creating Packing List',
    confirm_cancel_current_packing_list: 'Cancel current packing list',
    cancel_current_packing_list: 'Are you sure you want to cancel the current Packing List?',
    create_new_ddt: 'Confirm to create a new DDT',
    create_new_ddt_title: 'Creating DDT',
    change_items: 'Change Items',
    approve_items: 'Approve Items',
    change_exec_phase: 'Change Phase',
    confirm_exec_phases: 'Confirm Execution Phases',
    confirm_reject_change_code: 'Confirm Change Code Rejection',
    reject_change_code: 'Reject',
  },
  labels: {
    draft: 'Draft',
    ready_to_send: 'Ready to send',
    send_await: 'Pending',
    outbound_error: 'Outbound error',
    sent: 'Sent',
    in_inbound: 'In Inbound',
    receive_await: 'Pending',
    ready_to_receive: 'To receive',
    inbound_error: 'Inbound error',
    received: 'Received',
    in_transit: 'In transit',
    wait_for_ddt: 'Wait for DDT',
    wait_for_cq: 'Wait for CQ',
    completed: 'Completed',
    error_pl: 'PL error',
    cq_in_progress: 'CQ in progress',
    parcels_confirmed: 'Parcels confirmed',
    ok: 'Ok',
    confirm_error: 'Confirm error',
    cq_error: 'CQ error',
    cancelled: 'Cancelled',
    grade_a: 'Grade A',
    grade_b: 'Grade B',
    grade_c: 'Grade C',
    grade_d: 'Grade D',
    japan: 'Japan',
    failed: 'Failed',
    toneUndefined: '0 - Remove Tone',
    none: 'None',
    change_requested: 'Change Requested',
    change_await: 'Change Await',
    change_approved: 'Change Approved',
    change_pending: 'Change Pending',
  },
  appSystem: {
    isPLNoteModalEnabled: 'isPLNoteModalEnabled',
    enableCQ79: 'enableCQ79',
  },
}
